import {translate} from "./i18n.js";

window.getCookies = function() {
    let cookies = {};
    document.cookie.split(`;`).forEach(function(el) {
        let [key, value] = el.split(`=`);
        cookies[key.trim()] = value;
    })
    return cookies;
}

window.getCookie = function(name) {
    let cookies = getCookies();
    if (cookies) {
        return cookies[name];
    } else {
        return null;
    }
}

window.getLayoutWidth = function() {
    return document.documentElement.clientWidth;
};
window.getLayoutHeight = function() {
    return document.documentElement.clientHeight;
};
window.updateWindows = function() {
    // mobile and tablet in portrait mode
    window.isMobileLayout = window.getLayoutWidth() <= 768;
    window.isSmallLayout = window.getLayoutWidth() <= 768; // legacy
    // tablets in landscape mode
    window.isTabletLayout = window.getLayoutWidth() > 768 && window.getLayoutWidth() <= 1024;
    window.isMobileOrTabletLayout = window.getLayoutWidth() <= 1024 && window.getLayoutHeight() <= 1500;

    window.isLargeLayout = window.getLayoutWidth() > 1024 || window.getLayoutHeight() >= 1500;
    window.isExtraLargeLayout = window.getLayoutWidth() >= 1368;
};

window.updateWindows();

window.addEventListener(`resize`, () => {
    window.updateWindows();
});

const appHeight = function() {
    const doc = document.documentElement;
    doc.style.setProperty(`--app-height`, `${window.innerHeight}px`);
}
window.addEventListener(`resize`, appHeight)
appHeight()

export function download(fileName, content, dataType = `text/plain;charset=utf-8`) {
    let element = document.createElement(`a`);
    element.setAttribute(`href`, `data:${dataType},` + encodeURIComponent(content));
    element.setAttribute(`download`, fileName);

    element.style.display = `none`;
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function getNestedValue(item, fieldPath) {
    if (!fieldPath || typeof fieldPath !== `string`) {
        return;
    }

    if (fieldPath.indexOf(`.`) === -1) {
        return item[fieldPath];
    }

    // Get value from Nested Object xxx.floor.name  [root]?optional.[type].[field]
    const path = fieldPath.split(`.`);
    let value = item;
    for (let key of path) {
        if (value.hasOwnProperty(key)) {
            value = value[key];
        } else {
            return;
        }
    }
    return value;
}

export function getMarketIcon(market) {
    switch (market) {
        case `coffee_shop`:
            return `fas fa-mug-hot`;
        case `residential`:
        case `college_residence`:
            return `fas fa-graduation-cap`;
        case `shopping_mall`:
            return `fas fa-shopping-cart`;
        case `restaurant`:
            return `fas fa-utensils-alt`;
        case `sports_club`:
            return `fas fa-running`;
        case `hotel`:
            return `fas fa-hotel`;
        case `transportation`:
            return `fas fa-bus`;
        case `medical`:
            return `fas fa-clinic-medical`;
        case `municipality`:
            return `fas fa-city`;
        case `fast_food`:
            return `fas fa-burger-soda`;
        case `financial`:
            return `fas fa-coins`;
        case `office`:
            return `fas fa-phone-office`;
        case `special_event`:
            return `fas fa-goal-net`
        case `electronic_retail`:
            return `fas fa-microchip`;
        case `clothing_retail`:
            return `fas fa-shirt`;
        default:
            return `fas fa-store`
    }
}

export function getMarketName(market) {
    switch (market) {
        case `coffee_shop`:
            return translate(`Coffee Shop|Café`)
        case `residential`:
            return translate(`Residential|Résidentiel`)
        case `college_residence`:
            return translate(`College Residence|Résidentiel Scolaire`)
        case `shopping_mall`:
            return translate(`Shopping Mall|Centre Commercial`)
        case `restaurant`:
            return translate(`Restaurant|Restaurant`)
        case `sports_club`:
            return translate(`Sports Club|Club sportif`)
        case `hotel`:
            return translate(`Hotel|Hôtel`)
        case `transportation`:
            return translate(`Transportation|Transport`)
        case `medical`:
            return translate(`Medical|Médical`)
        case `municipality`:
            return translate(`Municipality|Municipalité`)
        case `fast_food`:
            return translate(`Fast Food|Rest. rapide`)
        case `financial`:
            return translate(`Financial|Financier`)
        case `office`:
            return translate(`Office|Bureau`)
        case `special_event`:
            return translate(`Special Event|Évènement Spécial`)
        case `electronic_retail`:
            return translate(`Electronic Retail|Magasin électronique`)
        case `clothing_retail`:
            return translate(`Clothing Retail|Magasin vêtements`)
        case `other_retail`:
            return translate(`Other Retail|Autre magasin`)
        case `test_site`:
            return translate(`Test Site|Site de test`)
        default:
            return market;
    }
}

export function getCurrencyIcon(currency) {
    switch (currency) {
        case `CAD`:
        case `USD`:
            return `fa-dollar-sign`;
        case `EUR`:
            return `fa-euro-sign`;
        default:
            return `fa-question`;
    }
}

export function getGoogleStaticMap(postalCode, latitude, longitude, width = 350, height = 200, zoom = 13) {
    if (!isStringEmpty(postalCode)) {
        postalCode = postalCode.toString().replace(` `, `%20`);
        return `https://maps.googleapis.com/maps/api/staticmap?center=${postalCode}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&key=AIzaSyCw92xwj8_rZBtLA2xZtt87UJy4Cek4nyQ`;
    } else if (!isStringEmpty(latitude) && !isStringEmpty(longitude)) {
        var coordinates = latitude + `,%20` + longitude;
        return `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&key=AIzaSyCw92xwj8_rZBtLA2xZtt87UJy4Cek4nyQ`;
    } else {
        return null;
    }
}

export function checkPostalCode(value) {
    if (isStringEmpty(value)) {
        return false;
    }

    let type = `ca`;
    if (value.length === 5) {
        type = `us`;
    }

    if (type === `us`) {
        const us = new RegExp(`^\\d{5}(-{0,1}\\d{4})?$`);
        if (us.test(value.toString())) {
            return true;
        }
    }

    if (type === `ca`) {
        const ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);
        if (ca.test(value.toString())) {
            return true;
        }
    }

    return false;
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

export function passwordSecured(password) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,32}$/.test(password); // 8 chars, at least 1 uppercase, at least 1 number
}

export function emailValid(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email);
}

export function cleanPhoneNumber(number) {
    return `+` + number.replace(/[^\d]/g, ``);
}

// Useful for Vue good table sort
export function compareNumbers(x, y) {
    return (x < y ? -1 : (x > y ? 1 : 0));
}

/** Utility for iOS Mobile with top Notch (iPhone X and more) */
export function getSafeArea() {
    // Require CSS weird code from mobile.scss
    const style = getComputedStyle(document.documentElement);
    if (!style.getPropertyValue(`--sat`)) {
        return {
            enabled: false,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
    return {
        enabled: true,
        top: parseInt(style.getPropertyValue(`--sat`)),
        bottom: parseInt(style.getPropertyValue(`--sab`)),
        left: parseInt(style.getPropertyValue(`--sal`)),
        right: parseInt(style.getPropertyValue(`--sar`))
    };
}

export function getContentTypeFromExtension(extension) {
    const types = {
        'html': `text/html`,
        'htm': `text/html`,
        'shtml': `text/html`,
        'css': `text/css`,
        'xml': `text/xml`,
        'gif': `image/gif`,
        'jpg': `image/jpeg`,
        'jpeg': `image/jpeg`,
        'js': `text/javascript`,
        'atom': `application/atom+xml`,
        'rss': `application/rss+xml`,
        'mml': `text/mathml`,
        'txt': `text/plain`,
        'jad': `text/vnd.sun.j2me.app-descriptor`,
        'wml': `text/vnd.wap.wml`,
        'htc': `text/x-component`,
        'png': `image/png`,
        'tiff': `image/tiff`,
        'tif': `image/tiff`,
        'wbmp': `image/vnd.wap.wbmp`,
        'ico': `image/x-icon`,
        'jng': `image/x-jng`,
        'bmp': `image/x-ms-bmp`,
        'svg': `image/svg+xml`,
        'webp': `image/webp`,
        'jar': `application/java-archive`,
        'war': `application/java-archive`,
        'ear': `application/java-archive`,
        'hqx': `application/mac-binhex40`,
        'doc': `application/msword`,
        'pdf': `application/pdf`,
        'ps': `application/postscript`,
        'eps': `application/postscript`,
        'ai': `application/postscript`,
        'rtf': `application/rtf`,
        'xls': `application/vnd.ms-excel`,
        'ppt': `application/vnd.ms-powerpoint`,
        'wmlc': `application/vnd.wap.wmlc`,
        'kml': `application/vnd.google-earth.kml+xml`,
        'kmz': `application/vnd.google-earth.kmz`,
        '7z': `application/x-7z-compressed`,
        'cco': `application/x-cocoa`,
        'jardiff': `application/x-java-archive-diff`,
        'jnlp': `application/x-java-jnlp-file`,
        'run': `application/x-makeself`,
        'pl': `application/x-perl`,
        'pm': `application/x-perl`,
        'prc': `application/x-pilot`,
        'pdb': `application/x-pilot`,
        'rar': `application/x-rar-compressed`,
        'rpm': `application/x-redhat-package-manager`,
        'sea': `application/x-sea`,
        'swf': `application/x-shockwave-flash`,
        'sit': `application/x-stuffit`,
        'tcl': `application/x-tcl`,
        'tk': `application/x-tcl`,
        'der': `application/x-x509-ca-cert`,
        'pem': `application/x-x509-ca-cert`,
        'crt': `application/x-x509-ca-cert`,
        'xpi': `application/x-xpinstall`,
        'zip': `application/zip`,
        'exe': `application/octet-stream`,
        'bin': `application/octet-stream`,
        'dll': `application/octet-stream`,
        'deb': `application/octet-stream`,
        'dmg': `application/octet-stream`,
        'eot': `application/octet-stream`,
        'iso': `application/octet-stream`,
        'img': `application/octet-stream`,
        'msi': `application/octet-stream`,
        'msp': `application/octet-stream`,
        'msm': `application/octet-stream`,
        'mid': `audio/midi`,
        'midi': `audio/midi`,
        'kar': `audio/midi`,
        'mp3': `audio/mpeg`,
        'ogg': `audio/ogg`,
        'ra': `audio/x-realaudio`,
        '3gpp': `video/3gpp`,
        '3gp': `video/3gpp`,
        'mpeg': `video/mpeg`,
        'mpg': `video/mpeg`,
        'mov': `video/quicktime`,
        'flv': `video/x-flv`,
        'mng': `video/x-mng`,
        'asx': `video/x-ms-asf`,
        'asf': `video/x-ms-asf`,
        'wmv': `video/x-ms-wmv`,
        'avi': `video/x-msvideo`,
        'mp4': `video/mp4`,
        'm4v': `video/mp4`
    }

    return types.hasOwnProperty(extension) ? types[extension] : `application/octet-stream`;
}

// In km
export function getDistanceBetweenCoordinates(origin, destination) {
    // return distance in meters
    const lon1 = toRadian(origin[1]);
    const lat1 = toRadian(origin[0]);
    const lon2 = toRadian(destination[1]);
    const lat2 = toRadian(destination[0]);

    let deltaLat = lat2 - lat1;
    let deltaLon = lon2 - lon1;

    let a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    let c = 2 * Math.asin(Math.sqrt(a));
    let EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS;
}

export function toRadian(degree) {
    return degree * Math.PI / 180;
}

export function getCoordinateBounds(lat, lng, radiusMeters = 50000) {
    let bounds = {
        northEast: {lat: null, lng: null},
        southWest: {lat: null, lng: null}
    }

    const oneDegreeOfLatitudeInMeters = 111.32 * 1000;

    const latDelta = radiusMeters / oneDegreeOfLatitudeInMeters;
    const lngDelta = radiusMeters / (oneDegreeOfLatitudeInMeters * Math.cos(lat * (Math.PI / 180)));

    bounds.northEast.lat = lat - latDelta;
    bounds.northEast.lng = lng - lngDelta;

    bounds.southWest.lat = lat + latDelta;
    bounds.southWest.lng = lng + lngDelta;

    return bounds;
}

export function getSimpleGoogleAddressComponents(addrComponents) {
    let result = {};
    for (let component of addrComponents) {
        result[component.types[0]] = component.short_name;
    }
    return result;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If 'immediate' is passed, trigger the function on the leading edge, instead of the trailing.
 * @param func
 * @param wait
 * @param immediate
 * @returns {function(): *}
 */
export function debounce(func, wait = 300, immediate = false) {
    let timeout, result;
    return function() {
        let context = this;
        let args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate)
                result = func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            result = func.apply(context, args);
        return result;
    };
}

export function incrementSet(set, key, inc = 1) {
    if (!set[key]) {
        set[key] = inc;
    } else {
        set[key] += inc;
    }
}
export function convertSetToArray(set, labelKey = `label`, valueKey = `value`) {
    let array = [];
    for (let key in set) {
        let value = {};
        value[labelKey] = key;
        value[valueKey] = set[key];
        array.push(value);
    }
    return array;
}

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export default {};
