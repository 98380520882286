<template>
    <div id="app" :class="[`layout-${displayMode}`, {'app-ready': $store.state.applicationReady, 'accessibility-wheelchair': $store.state.accessibility.wheelchair}]">
        <keep-alive>
            <router-view v-if="$store.state.applicationReady"/>
        </keep-alive>

        <div v-if="$store.state.accessibility.wheelchair && $store.state.website" class="wheelchair-placeholder">
            <b-img :src="cmsWebsite?.accessibility.wheelchair.placeholder.src"/>

            <div class="panel-title">
                <div class="panel-container">
                    <i class="fa-wheelchair fa-2x mr-2 float-left" :class="fontAwesomePrefix"/>
                    <h3 class="" v-tr>Accessible Mode|Mode accessible</h3>

                    <b-btn class="ml-3" variant="light" pill @click="$store.commit(`setAccessibilityWheelchair`, false)">
                        <span v-tr>Exit|Quitter</span>
                        <i class="fa-close ml-1" :class="fontAwesomePrefix"/>
                    </b-btn>
                </div>
            </div>
        </div>
        <!--        <e-loading-animation v-if="!$store.state.applicationReady"/>-->
        <b-progress class="website-loading" value="100" animated v-if="$store.state.applicationLoading"/>
    </div>
</template>

<script>
import ELoadingAnimation from "../vue-components/components/e-loading-animation.vue";
import {getLanguage, setLanguage, translate} from "../vue-components/helpers/i18n"
import moment from "moment"
import SessionTracker from "./helpers/SessionTracker.js";

// app.vue #hot-reload-debug
export default {
    name: `App`,
    components: {ELoadingAnimation},
    metaInfo() {
        if (this.cmsWebsite) {
            return {
                title: translate(`Eye-In CMS|Editeur CMS`),
                titleTemplate: `%s | ${this.tr(this.cmsWebsite.meta.title)}`
            }
        } else {
            return null;
        }
    },
    data() {
        return {
            idleTimeout: null,
            ready: true // True by default except on the login vue
        };
    },
    computed: {
        cssVar() {
            let variables = {
                wheelchair: {
                    topMargin: this.cmsWebsite ? this.cmsWebsite.accessibility.wheelchair.topMargin : `800px`,
                    background: this.cmsWebsite ? this.cmsWebsite.getColor(this.cmsWebsite.accessibility.wheelchair.background) : `#000`,
                }
            };

            return variables;
        },
        isUserConnected() {
            return this.ready === true && this.$store.getters.isAuthenticated;
        },
        isEmbed() {
            return this.$route.query.embed === `true`;
        }
    },
    async created() {
        // don't use $route here
        const urlParams = new URLSearchParams(window.location.search);

        // Use store because session can't be created here, we need to wait for the cms-page-selector
        // Since there might be a redirection don't put this code in cms-page-selector

        if (parent !== window) {
            this.$store.state.sessionData.medium = `iframe`;
            this.$store.state.sessionData.source = document.referrer;
        }

        // Google Standard UTM Parameters
        this.$store.state.sessionData.medium = urlParams.get(`medium`);
        this.$store.state.sessionData.source = urlParams.get(`source`);

        this.$store.state.sessionData.utms.utm_medium = urlParams.get(`utm_medium`);
        this.$store.state.sessionData.utms.utm_source = urlParams.get(`utm_source`);
        this.$store.state.sessionData.utms.utm_campaign = urlParams.get(`utm_campaign`);
        this.$store.state.sessionData.utms.utm_term = urlParams.get(`utm_term`);
        this.$store.state.sessionData.utms.utm_content = urlParams.get(`utm_content`);

        // Advanced params
        if (urlParams.get(`display`) === `kiosk` || localStorage.getItem(`kiosk`) === `yes`) {
            this.$store.state.isKioskDevice = true;
            window.isKioskDevice = true;
            localStorage.setItem(`kiosk`, `yes`);

            this.$store.state.idleTimeout = parseInt(urlParams.get(`timeout`) || localStorage.getItem(`idleTimeout`));
            if (this.$store.state.idleTimeout) {
                localStorage.setItem(`idleTimeout`, this.$store.state.idleTimeout);
            }

            this.$store.state.playerId = urlParams.get(`playerId`) || urlParams.get(`player`) || localStorage.getItem(`playerId`);
            if (this.$store.state.playerId) {
                localStorage.setItem(`playerId`, this.$store.state.playerId);
                this.$store.state.sessionData.medium = `player`;
                this.$store.state.sessionData.source = this.$store.state.playerId;
            }
        } else {
            window.isKioskDevice = false;
        }

        if (urlParams.get(`qr`)) {
            localStorage.setItem(`qrCode`, urlParams.get(`qr`));
            this.$store.state.sessionData.medium = `qr-code`;
            this.$store.state.sessionData.source = urlParams.get(`qr`);
        }

        if (urlParams.get(`iframe`) === `website`) {
            this.$store.state.sessionData.medium =  `iframe`;
            this.$store.state.sessionData.source = `www.ciusss-ouestmtl.gouv.qc.ca`;
            localStorage.setItem(`cookiesAccepted`, `true`);
        }

        if (urlParams.get(`lang`)) {
            setLanguage(urlParams.get(`lang`));
        }

        const language = getLanguage();
        moment.locale(language);

        this.onWindowResize();
        window.addEventListener(`resize`, this.onWindowResize);
    },
    mounted() {
        if (this.isKioskDevice && this.$store.state.idleTimeout) {
            window.addEventListener(`click`, this.resetIdleTimeout.bind(this));
            this.resetIdleTimeout();
        }

        if (localStorage.getItem(`cookiesAccepted`) === null && !isKioskDevice) {
            this.showCookieToast();
        }
    },
    beforeDestroy() {
        SessionTracker.clean();
    },
    methods: {
        onWindowResize() {
            this.$store.state.isMobileLayout = window.isMobileLayout;
            this.$store.state.isSmallLayout = window.isSmallLayout;
            this.$store.state.isTabletLayout = window.isTabletLayout;
            this.$store.state.isMobileOrTabletLayout = window.isMobileOrTabletLayout;
            this.$store.state.isLargeLayout = window.isLargeLayout;
            this.$store.state.isExtraLargeLayout = window.isExtraLargeLayout;
            this.$store.commit(`updateDisplayMode`);
        },
        showCookieToast() {
            const h = this.$createElement;
            const vNodesMsg = h(
                `div`, {class: [`cookie-toast-msg d-flex flex-column`]},
                [
                    h(`span`, {class: [`mb-2`]}, translate(`Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies. | Les cookies nous aident à offrir une meilleure expérience sur notre site. En utilisant notre site, vous acceptez l'utilisation des cookies.`)),
                    h(`div`, {class: [`d-flex`]},
                        [
                            h(`b-btn`, {class: [`ml-auto`], props: {variant: `primary`}, on: {click: this.acceptCookies}}, translate(`Accept | Accepter`)),
                        ]
                    )
                ]
            );
            const vNodesTitle = h(
                `div`,
                {class: [`cookie-toast-title`]},
                [h(`i`, {class: [`far fa-cookie-bite mr-2`]}), h(`strong`, translate(`Cookie Consent | Consentement aux cookies`))]
            );
            this.$bvToast.toast([vNodesMsg], {
                id: `cookie-toast`,
                title: [vNodesTitle],
                solid: true,
                variant: `info`,
                noAutoHide: true,
                toaster: `b-toaster-bottom-right`,
            });
        },
        acceptCookies() {
            this.$bvToast.hide(`cookie-toast`);
            localStorage.setItem(`cookiesAccepted`, `true`);
        },
        resetIdleTimeout() {
            console.log(`Idle Timeout Check`);

            if (this.idleTimeout) {
                clearTimeout(this.idleTimeout);
            }
            this.idleTimeout = setTimeout(() => {
                console.log(`Show Idle Screen`);
                if (window.top) {
                    console.log(`window.top found`);
                    window.top.postMessage({
                        type: `widget`,
                        action: `show-idle-screen`
                    }, `*`);
                } else {
                    console.warn(`window.top NOT found`);
                }

                if (window.parent) {
                    console.log(`window.parent found`);
                    window.parent.postMessage({
                        type: `widget`,
                        action: `show-idle-screen`
                    }, `*`);
                } else {
                    console.warn(`window.parent NOT found`);
                }

                if (this.$route.path === `/` && this.language === `fr`) {
                    console.log(`Already on home page, no need to reset page`);
                    if (this.isWheelchairMode) {
                        this.$store.commit(`setAccessibilityWheelchair`, false);
                    }
                    this.resetIdleTimeout();
                } else {
                    console.log(`Reset to default page`);
                    setLanguage(`fr`);
                    window.history.pushState({}, ``, `/`);
                    window.location.reload();
                }
            }, this.$store.state.idleTimeout * 1000);
        }
    }
}
</script>

<style lang="scss">
@import "assets/style/global.scss";

html {
    height: var(--app-height);
}

body {
    width: 100%;
    height: 100vh;
    height: var(--app-height);
    padding: 0;
    font-family: var(--body-font);
    //font-size: 13pt;
    position: relative;
    overflow: hidden auto;
    container-type: inline-size;
}

#app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: var(--app-height);
    --wheelchair-top-margin: v-bind('cssVar.wheelchair.topMargin');

    &.accessibility-wheelchair {
        padding-top: var(--wheelchair-top-margin) !important;
        --app-height: calc(100vh - var(--wheelchair-top-margin)) !important;
    }
}

.wheelchair-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--wheelchair-top-margin);
    z-index: 0;
    overflow: hidden;

    img {
        width: 100%;
        height: calc(100% - 100px);
        object-fit: cover;
    }

    .panel-title {
        width: 100%;
        height: 100px;
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: v-bind('cssVar.wheelchair.background');

        .panel-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            border-radius: 50px;
            padding: 10px 10px 10px 20px;
        }

        .btn {
            padding: 8px 20px;
            font-size: 15pt;
            height: 50px;
        }
    }
}

.progress.website-loading {
    position: fixed;
    height: 3px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    border-radius: 0;
}
</style>
