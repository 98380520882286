import Vue from "vue";
import "../vue-components/helpers/vue-filters.js";
import "../vue-components/helpers/vue-directives.js";
import "./vue-cms-directives.js";
import "../vue-components/helpers/vue-mixin.js"
import {getLanguage} from "../vue-components/helpers/i18n.js";
import PermissionHelper from "../vue-components/helpers/PermissionHelper.js";

Vue.mixin({
    computed: {
        displayMode() {
            return this.$store.state.displayMode;
        },
        previewMode: {
            get() {
                return this.$store.state.previewMode;
            },
            set(value) {
                this.$store.state.previewMode = value;
            }
        },
        isWheelchairMode() {
            return this.$store.state.accessibility.wheelchair;
        },
        isKioskDevice() {
            return this.$store.state.displayMode === `kiosk`;
        },
        isMobileDevice() {
            return this.$store.state.displayMode === `mobile` || this.$store.state.previewMode === `mobile`;
        },
        isTabletDevice() {
            return this.$store.state.displayMode === `tablet` || this.$store.state.previewMode === `tablet`;
        },
        isDesktopDevice() {
            return this.$store.state.displayMode === `desktop`;
        },
        isMobileLayoutOrPreview() {
            return this.$store.state.isMobileLayout || this.$store.state.previewMode === `mobile`;
        },
        isTabletLayoutOrPreview() {
            return this.$store.state.isTabletLayout || this.$store.state.previewMode === `tablet`;
        },
        isMobileOrTabletLayoutOrPreview() {
            return this.$store.state.isMobileOrTabletLayout || this.$store.state.previewMode === `mobile` || this.$store.state.previewMode === `tablet`;
        },
        dealerid() {
            return this.$store.state.website?.dealerid;
        },
        cms() {
            return this.$store.state.cms;
        },
        cmsItems() {
            return this.$store.state.cms.items;
        },
        cmsTypes() {
            return this.$store.state.cms.types;
        },
        // todo replace with cmsWebsite
        website() {
            return this.$store.state.website;
        },
        cmsWebsite() {
            return this.$store.state.website;
        },
        cmsPage() {
            return this.$store.state.page;
        },
        cmsPageItem() {
            if (this.$store.state.page) {
                return this.$store.state.cms.getFirstItemByType(this.$store.state.page.itemType);
            } else {
                return null;
            }
        },
        cmsPages() {
            if (this.$store.state.website) {
                return this.$store.state.website.pages;
            } else {
                return [];
            }
        },
        isEditMode() {
            return this.$store.state.editMode;
        },
        fontAwesomePrefix() {
            return this.$store.state.website.design.fontAwesome.prefix;
        },
        displayLanguage() {
            if (this.$store.state.previewLanguage) {
                return this.$store.state.previewLanguage;
            } else {
                return getLanguage();
            }
        }
    },
    methods: {
        isAccessRestricted() {
            return this.currentUser && (this.currentUser.access_restricted === 1 || this.currentUser.access_restricted === true);
        },
        canRead(permission) {
            return PermissionHelper.can(`read`, permission);
        },
        canWrite(permission) {
            return PermissionHelper.can(`write`, permission);
        },
        canAdmin(permission) {
            return PermissionHelper.can(`admin`, permission);
        },
        canAdminEyeInWireless() {
            return PermissionHelper.can(`admin`, null, `Eye-In Wireless`);
        },
        isDealerParent() {
            return this.canAdminEyeInWireless();
        },
        isEyeInWireless() {
            return this.dealerid.toLowerCase() === `eye-in wireless`;
        },
    }
});

export default {};
