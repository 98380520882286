import CmsAnalyticsSession from "../../common/db/models/cms/CmsAnalyticsSession.js";
import NetworkV2 from "../../vue-components/helpers/NetworkV2.js";

export default class SessionTracker {
    static session;

    static init(globalSessionInfo = {}) {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        window.SessionTracker = SessionTracker;
        this.retrieveSession(globalSessionInfo);

        document.addEventListener(`pointerup`, this.handleClick.bind(this));
        document.addEventListener(`scrollend`, this.handleScroll.bind(this), {capture: true});
        // document.addEventListener(`visibilitychange`, this.handleVisibility.bind(this));
    }

    static clean() {
        document.removeEventListener(`pointerup`, this.handleClick.bind(this));
        document.removeEventListener(`scrollend`, this.handleScroll.bind(this), {capture: true});
        // document.removeEventListener(`visibilitychange`, this.handleVisibility.bind(this));
    }

    static async handleClick(event) {
        const element = event.target.nodeName === `SPAN` ? event.target.parentNode : event.target;

        const action = {
            tag: element.nodeName.toLowerCase(),
            tag_id: event.target.id,
            text: event.target.textContent,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
            pageX: event.pageX,
            pageY: event.pageY,
            clientX: event.clientX,
            clientY: event.clientY,
            screenX: event.screenX,
            screenY: event.screenY
        }

        await this.addCustomAction(`click`, action);
    }

    static async handleScroll(event) {
        const element = event.target.nodeName === `SPAN` ? event.target.parentNode : event.target;

        await this.addCustomAction(`scroll`, {
            tag: element.nodeName.toLowerCase(),
            tag_id: event.target.id,
            classes: [...event.target.classList]
        });
    }

    // static handleVisibility(event) {
    //     this.addCustomAction(`visibilitychange`, {
    //         visible: !document.hidden
    //     });
    // }

    static async createNewSession(globalSessionInfo = {}) {
        this.session = new CmsAnalyticsSession({
            userAgent: navigator.userAgent,
            origin: window.location.origin,
            ...globalSessionInfo
        });
        this.session.language = localStorage.getItem(`language`) || navigator.language.substring(0, 2);
        const resp = await NetworkV2.post(`/api/public/analytics/sessions/init`, this.session.toBody());
        this.session = new CmsAnalyticsSession(resp.data);

        sessionStorage.setItem(`session_id`, this.session.id);
    }

    static async retrieveSession(globalSessionInfo = {}) {
        const sessionId = sessionStorage.getItem(`session_id`);
        if (sessionId && sessionId !== `null`) {
            await this.createNewSession({id: sessionId, ...globalSessionInfo});
        } else {
            await this.createNewSession(globalSessionInfo);
        }
    }

    static async addCustomAction(type, action) {
        if (!this.session) {
            return;
        }

        action.type = type;
        action.date = new Date();
        if (globalThis.location) {
            action.path = globalThis.location.pathname;
        }

        try {
            await NetworkV2.post(`/api/public/analytics/actions/add`, {
                sessionId: this.session.id,
                action: action
            });
        } catch(e) {
            console.error(e);
        }
    }
}
