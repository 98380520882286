import FileLibraryHelper from "@/helpers/cms/FileLibraryHelper.js";
import {tr} from "../../../vue-components/helpers/i18n.js";
import MapBaseClass from "./MapBaseClass.js";

export default class MapItem extends MapBaseClass {
    get ignoredFields() {
        return [`map`, `links`, `settings`];
    }

    constructor(data, map) {
        super(data);
        this.map = map;

        this.links = [];

        if (!this.navRule) {
            // Define if the cost of taking this item should be higher
            // recommended | default | not-recommended | avoid | unavailable
            this.navRule = `default`;
        }
        // NavCost is based on distance and navRule
        this.navCost = 0;

        this.offsetAngle = this.image360?.offsetAngle || 0;
        this.image360 = FileLibraryHelper.getCacheUrl(this.image360);
        this.image = FileLibraryHelper.getCacheUrl(this.image);

        // old compatibility
        if (!this.floors) {
            this.floors = {
                list: {},
                multiple: false,
                levelOffset: 0 // when the ground is not flat and elevators have different floors
            };

            if (this.multiFloors) {
                this.floors.multiple = true;
                delete this.multiFloors;

                for (let id of data.floors) {
                    this.floors.list[id] = {
                        accessible: true,
                        name: null
                    };
                }
            }
        }

        this.updateNavCost();
    }

    getName() {
        /*if (this.cmsItem) {
            return tr(this.cmsItem.name);
        } else */if (this.label) {
            return tr(this.label);
        } else if (this.name) {
            return this.name;
        } else if (this.type !== `nav-point` && this.type !== `qr-code` && this.type !== `entrance`) {
            return this.type;
        } else {
            return null;
        }
    }

    getIcon() {
        switch (this.type) {
            case `toilet`:
                if (this.accessible) {
                    return getPublicIcon(`restroom-accessible`);
                } else {
                    return getPublicIcon(`restroom`);
                }
            default:
                return getPublicIcon(this.type);
        }
    }

    getFullName() {
        if (this.cmsItem) {
            return tr(this.cmsItem.getName());
        } else {
            return this.getName();
        }
    }

    getLocationDescription() {
        if (tr(this.location)) {
            return tr(this.location);
        } else {
            if (this.cmsItem) {
                return this.getFullName();
            }
            switch (this.type) {
                case `elevator`:
                    return tr(`The Elevator|Les ascenseurs`);
                case `stairs`:
                    return tr(`The Stairs|Les escaliers`);
                case `door`:
                    return tr(`The Door|La porte`);
                case `escalator`:
                    return tr(`The Escalator|L'escalator`);
                case `emergency-exit`:
                    return tr(`The Emergency Exit|La sortie de secours`);
                case `entrance`:
                    if (this.label) {
                        return tr(this.label);
                    } else {
                        return tr(`The Entrance/Exit|L'entrée/sortie`);
                    }
                case `atm`:
                    return tr(`The ATM|Le guichet bancaire`);
            }
        }
    }

    matchPermalink(permalink) {
        if (this.permalink && typeof this.permalink === `string` && permalink === this.permalink) {
            return true;
        } else if (this.permalink && typeof this.permalink === `object` &&
            (permalink === this.permalink.en || permalink === this.permalink.default || permalink === this.permalink.fr)) {
            return true;
        }
        return permalink === this.id;
    }

    getPermalink() {
        if (this.permalink) {
            return tr(this.permalink);
        } else {
            return this.id;
        }
    }

    setCmsItem(cmsItem) {
        this.cmsItem = cmsItem;
        if (this.cmsItem) {
            this.permalink = this.cmsItem.permalink;
        }
    }

    updateNavCost() {
        this.navCost = 0;
        if (this.type === `elevator`) {
            this.navCost += 20;
        } else if (this.type === `stairs`) {
            this.navCost += 50;
        }
        switch (this.navRule) {
            case `recommended`:
                this.navCost -= 5;
                break;
            case `not-recommended`:
                this.navCost += 5;
                break;
            case `avoid`:
                this.navCost += 10;
                break;
            case `unavailable`:
                this.navCost += 999;
                break;
        }
    }

    isOnFloor(floorId) {
        return this.floorId === floorId ||
            (this.floors.multiple && this.floors.list[floorId] && this.floors.list[floorId].accessible);
    }

    addLink(link) {
        // const existingLink = this.links.find(l => l.id === linkedItem.id);
        this.links.push(link)
    }

    removeLink(link) {
        this.links.removeItem(link);
    }
}
